import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import Banner from "./banner";
import "../styleSheets/home.scss";
import workplace from "../images/workdayLogo.png";
import iconHelp from "../images/iconHelp.png";
import linkLogo from "../images/linkWhite.png";
import adpLogo from "../images/adpLogo.png";
import bookicon from "../images/book.png"
import { updateComponentAction } from "../store/actions/componentActions";
import { components } from "../constants/components";
import { homeText } from "../constants/homeText";

const Home = () => {

    const dispatch = useDispatch();
    const [redirectionUrl, setRedirectionURL] = useState("");
    const [videoId, setVideoID] = useState("");
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);

    useEffect(async () => {
        window.scrollTo(0, 0);
        dispatch(updateComponentAction(components.home.componentName));
        gapi.load('client', fetchPID);
        //let videoLink = await gapi.load('client',fetchVideoLink(pID));
    }, []);

    if (redirectionUrl != "") {
        return <Redirect push to={redirectionUrl} />
    }

    const fetchPID = () => {
        gapi.client.init({
            'apiKey': 'AIzaSyD730SKQ1w_xErENMPA8ScVxjW6l_3rUxA',
        }).then(function () {
            return gapi.client.request({
                'path': 'https://youtube.googleapis.com/youtube/v3/channels?part=contentDetails&forUsername=DPRConstruction&key=AIzaSyD730SKQ1w_xErENMPA8ScVxjW6l_3rUxA',
            })
        }).then(function (response) {
            let pID = response.result.items[0].contentDetails.relatedPlaylists.uploads;
            gapi.load('client', fetchVideoUrl(pID));
        }).catch(reject => {
            console.log(reject);
        });
    }

    const fetchVideoUrl = (pID) => {
        gapi.client.init({
            'apiKey': 'AIzaSyD730SKQ1w_xErENMPA8ScVxjW6l_3rUxA',
        }).then(function () {
            return gapi.client.request({
                'path': `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResult=1&playlistId=${pID}&key=AIzaSyD730SKQ1w_xErENMPA8ScVxjW6l_3rUxA`,
            })
        }).then(function (response) {
            let videoID = response.result.items[0].snippet.resourceId.videoId;
            setVideoID(videoID);
        }).catch(reject => {
            console.log(reject);
        });
    }


    const redirect = (event) => {
        switch (event.target.id) {
            case "workdayGuideButton": setRedirectionURL("/workday");
                break;
            case "adpGuideButton": setRedirectionURL("/adp");
                break;
            case "helpGuideButton": setRedirectionURL("/contacts");
                break;
        }
    }

    return <Container fluid className="homeContainer"> 
        <Row className="justify-content-center titleContainer">    
            <Col xs="12">
                <h2>{homeText[selectedLanguage].TITLE_HEADING}</h2>
                <p>
                    {homeText[selectedLanguage].TITLE_BODY_LINE1}<br />{homeText[selectedLanguage].TITLE_BODY_LINE2}
                </p>
            </Col>
        </Row>
        <Banner/> 
        <Row className="justify-content-center cardContainer">

            <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <Card className="workdayCard">
                    <Image src={workplace} className="workdayLogo" />
                    <Card.Body>
                        <Card.Text>
                            <strong>{homeText[selectedLanguage].WORKDAY_CARD_HEADING}</strong><br></br>
                            <strong>{homeText[selectedLanguage].LEARNING_CARD_HEADING}</strong>
                        </Card.Text>
                        <Button variant="outline-dark" id="workdayGuideButton" onClick={redirect}>{homeText[selectedLanguage].VIEW_GUIDES_BUTTON}</Button>
                        <a href="https://myapps.microsoft.com/signin/Workday/234a1a24502142e5a087335f9006d64b"><Button variant="primary" className="siteButton">
                            {homeText[selectedLanguage].SITE_REDIRECT_BUTTON} <Image src={linkLogo} className="linkLogo" /></Button></a>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <Card className="benefitsCard">
                    <h2 className="benefitsTitle">{homeText[selectedLanguage].DPR_BENEFITS_TITLE}</h2>
                    <Card.Body>
                        <Card.Text>
                            <strong>{homeText[selectedLanguage].DPR_BENEFITS_CARD_HEADING}</strong>
                        </Card.Text>
                        <a href="https://experience100.ehr.com/dprbenefits/"><Button variant="primary" className="siteButton">
                            {homeText[selectedLanguage].SITE_REDIRECT_BUTTON} <Image src={linkLogo} className="linkLogo" /></Button></a>
                    </Card.Body>
                </Card>
            </Col>
            <Col xs="12" sm="6" md="6" lg="4" xl="3">
                <Card className="adpCard">
                    <Image src={adpLogo} className="adpLogo" />
                    <Card.Body>
                        <Card.Text>
                            <strong>{homeText[selectedLanguage].ADP_CARD_HEADING}</strong>
                        </Card.Text>
                        <Button variant="outline-dark" id="adpGuideButton" onClick={redirect}>{homeText[selectedLanguage].VIEW_GUIDES_BUTTON}</Button>
                        <a href="https://myadp.com"><Button variant="primary" className="siteButton">
                            {homeText[selectedLanguage].SITE_REDIRECT_BUTTON} <Image src={linkLogo} className="linkLogo" /></Button></a>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="justify-content-center youtubeContainer">
            <Col xs="12">
                <iframe src={"https://www.youtube.com/embed/" + videoId} className="videoContainer"></iframe>
            </Col>
            <Col xs="12" className="dprYTlinkContainer">
                {homeText[selectedLanguage].YOUTUBE_TEXT1} <a href="https://www.youtube.com/user/DPRConstruction">
                    {homeText[selectedLanguage].YOUTUBE_TEXT2} <Image src={linkLogo} className="linkLogo" /></a>
            </Col>
        </Row>
        <Row className="justify-content-center helpContainer">
            <Col xs="12" sm="8" md="6" lg="4" className="helpCard">
                <div className="iconContainer">
                    <Image src={iconHelp} className="icon" />
                </div>
                <h6><strong>{homeText[selectedLanguage].HELP_CARD_HEADING}</strong></h6>
                <p>
                    {homeText[selectedLanguage].HELP_CARD_BODY}
                </p>
                <Button variant="outline-secondary" id="helpGuideButton" onClick={redirect}>{homeText[selectedLanguage].HELP_CARD_BUTTON}</Button>
            </Col>
            <Col xs="12" sm="8" md="6" lg="4" className="helpCard">
                <div className="bookiconContainer">
                    <Image src={bookicon} className="bookicon" />
                </div>
                <h6><strong>{homeText[selectedLanguage].EMPLOYEEGUIDEBOOK_CARD_HEADING}</strong></h6>
                <p>
                    {homeText[selectedLanguage].EMPLOYEEGUIDEBOOK_CARD_BODY}
                </p>
                <a href={`${homeText[selectedLanguage].LINK}`}>
                    <Button variant="outline-secondary" id="employeeGuideBookButton">{homeText[selectedLanguage].EMPLOYEEGUIDEBOOK_CARD_BUTTON}</Button>
                </a>
            </Col>
        </Row>
    </Container>
}

export default Home;
