import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../styleSheets/banner.scss";
import { homeText } from "../constants/homeText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";


const Banner = () => {
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);
    return  <Container fluid className="bannerContainer">
                <Row>
                    <Col xs="12">
                    <FontAwesomeIcon icon={faBullhorn} className="announcement-icon" />
                    {homeText[selectedLanguage].BANNER_TEXT1}       
                    </Col>
                </Row>
            </Container>
}

export default Banner;