//dependencies
import {actionTypes, component} from '../storeConstants';


export const updateComponentReducer = (state = component,actions) => {

    switch(actions.type){

        case actionTypes.UPDATE_COMPONENT : 
                return actions.payload;
                break;
        default : 
                return state;
                break;         
    }
}