export const components = {
    home : {
        componentName : "home",
        url : "/home",
        title : {
            "English" : "HOME",
            "Español" : "INICIO"
        }
    },

    workday : {
        componentName : "workday",
        url : "/workday",
        title : "WORKDAY"
    }
}