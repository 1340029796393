import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import "../styleSheets/adp.scss";
import adpLogo from "../images/adpLogo.png";
import { adpText } from "../constants/adpText";

import linkBlack from "../images/linkBlack.png";
import linkWhite from "../images/linkWhite.png";
import { updateComponentAction } from "../store/actions/componentActions";
import { components } from "../constants/components";
import arrowBack from "../images/arrowBack.png";


const Adp = () => {  

    const dispatch = useDispatch();
    const [redirectionUrl, setRedirectionURL] = useState("");
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);



    if (redirectionUrl != "") {
        return <Redirect push to={redirectionUrl} />
    }

    const redirect = () => {
        setRedirectionURL("/home");
    }

    return <Container fluid className="justify-content-center adpContainer">
        <Row className="adpTitleContainer">
            <Col xs="12">
                <h2>{adpText[selectedLanguage].TITLE_HEADING}</h2>
                <p>{adpText[selectedLanguage].TITLE_BODY} <a href="https://myadp.com"> ADP <Image src={linkBlack} className="linkLogo" /></a></p>
            </Col>
        </Row>
        <Row className="guideContainer justify-content-center">
            <Col xs="10">
                <h6>{adpText[selectedLanguage].GUIDE_HEADING}</h6>
                <p>
                    <span>{adpText[selectedLanguage].GUIDE_BODY1}</span>
                    <br />
                    <ul>
                        {
                            adpText[selectedLanguage].LINKS.map((element, index) => {
                                return <li key={index}>
                                    <a href={element.LINK} target="_blank" className="guideLinks"><strong>{element.TEXT}</strong></a>
                                    <br />
                                </li>
                            })
                        }
                    </ul>
                </p>
            </Col>
        </Row>
        <Row className="justify-content-center adpCardContainer">
            <Col xs="12">
                <Image src={adpLogo} className="adpImage" />
            </Col>
            <Col xs="12">
                <a href="https://myadp.com"><Button className="redirectButton">{adpText[selectedLanguage].SITE_REDIRECT_BUTTON} <Image src={linkWhite} className="linkLogo" /></Button></a>
            </Col>
        </Row>
        <Row className="redirectContainer">
            <Col>
                <Button variant="outline-secondary" onClick={redirect}><Image src={arrowBack} /> {adpText[selectedLanguage].HOME_REDIRECT_BUTTON}</Button>
            </Col>
        </Row>
    </Container>
}

export default Adp;