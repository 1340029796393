import React from "react";
import Card from "react-bootstrap/Card";
import "../styleSheets/contacts.scss";


const ContactCard = (props) => {

  const { name, role, email, cell } = props.person

  return (
    <Card.Body >
      <Card.Subtitle className="mb-2 text-muted">{role}</Card.Subtitle>
      <Card.Title className="mb-2">{name}</Card.Title>
      <h6>{email}</h6>
      <h6>{cell}</h6>
    </Card.Body>
  )
}

export default ContactCard
