import howToDeclareLearningNeedIn360Learning from "../documents/360learning/HowToDeclareLearningNeedIn360Learning.pdf"
import howToDeclareLearningNeedIn360LearningSP from "../documents/360learning/HowToDeclareLearningNeedIn360LearningSP.pdf"
import howToSearchAndRegisterForClassIn360Learning from "../documents/360learning/HowToSearchAndRegisterForClassIn360Learning.pdf"
import howToSearchAndRegisterForClassIn360LearningSP from "../documents/360learning/HowToSearchAndRegisterForClassIn360LearningSP.pdf"
import howToSetupYourProfileIn360Learning from "../documents/360learning/HowToSetupYourProfileIn360Learning.pdf"
import howToSetupYourProfileIn360LearningSP from "../documents/360learning/HowToSetupYourProfileIn360LearningSP.pdf"


export const learningText = {
    "English": {
        TITLE_HEADING: "How to Guides for 360Learning",
        TITLE_BODY: "Access the guides below. Or go straight to",
        GUIDE_HEADING: "How to Guides for 360Learning:",
        GUIDE_BODY1: `DPRLearning, powered by 360Learning, supports the Builder Skillset and Mindset framework
                        for how we develop and grow our people. Please refer to guides below to get started:`,
        LINKS: [{
            TEXT: "How to Declare a Learning Need in 360Learning.",
            LINK: howToDeclareLearningNeedIn360Learning
        }, {
            TEXT: "How to Search and Register for a Course in 360Learning.",
            LINK: howToSearchAndRegisterForClassIn360Learning
        }, {
            TEXT: "How to Setup Your Profile in 360Learning.",
            LINK: howToSetupYourProfileIn360Learning
        }],
        SITE_REDIRECT_BUTTON: "GO TO SITE",
        HOME_REDIRECT_BUTTON: "BACK TO PORTAL HOME"
    },
    "Español": {
        TITLE_HEADING: "Guías prácticas de 360 Learning",
        TITLE_BODY: "Accede a las guías a continuación. O dirígete directamente a",
        GUIDE_HEADING: "Guías prácticas de 360 Learning:",
        GUIDE_BODY1: `DPR Learning, con tecnología de 360 Learning, admite el marco de Conjunto de 
        habilidades y Mindset sobre cómo desarrollamos y hacemos crecer a nuestra gente. Consulta las guías a continuación para comenzar:`,
        LINKS: [{
            TEXT: "Cómo declarar una necesidad de aprendizaje en 360 Learning.",
            LINK: howToDeclareLearningNeedIn360LearningSP
        }, {
            TEXT: "Cómo buscar e inscribirse en un curso en 360 Learning.",
            LINK: howToSearchAndRegisterForClassIn360LearningSP
        }, {
            TEXT: "Cómo configurar tu perfil en 360 Learning.",
            LINK: howToSetupYourProfileIn360LearningSP
        }],
        SITE_REDIRECT_BUTTON: "IR AL SITIO",
        HOME_REDIRECT_BUTTON: "VOLVER A INICIO DEL PORTAL"
    }
}