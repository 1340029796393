import employeeGuideBook from "../documents/employeeguidebook/2021 DPR HR Guidebook - English.pdf";
import employeeGuideBookSP from "../documents/employeeguidebook/2021 DPR HR Guidebook - Spanish.pdf";


export const homeText = {
    "English": {
        TITLE_HEADING: "Craft Portal Home",
        TITLE_BODY_LINE1: "Access training programs, view important information",
        TITLE_BODY_LINE2: "and checkout the latest DPR videos.",
        LEARNING_CARD_HEADING: "Workday Learning: Curated learning programs",
        WORKDAY_CARD_HEADING: "My DPR: Employment and benefit details",
        DPR_BENEFITS_TITLE:"DPR Benefits",
        DPR_BENEFITS_CARD_HEADING:"DPR Benefit plan information available here",
        ADP_CARD_HEADING: "ADP: Access your paystubs and W2",
        VIEW_GUIDES_BUTTON: "VIEW GUIDES",
        SITE_REDIRECT_BUTTON: "GO TO SITE",
        YOUTUBE_TEXT1: "Catch up with",
        YOUTUBE_TEXT2: "DPR Craft on Youtube",
        HELP_CARD_HEADING: "Additional Questions?",
        HELP_CARD_BODY: `Find a local resource who can help.`,
        HELP_CARD_BUTTON: "Contacts",
        EMPLOYEEGUIDEBOOK_CARD_HEADING: "DPR Employee Guidebook",
        EMPLOYEEGUIDEBOOK_CARD_BODY: `DPR's current policies and guidelines to tools and resources.`,
        EMPLOYEEGUIDEBOOK_CARD_BUTTON: "View",
        LINK: employeeGuideBook,
        BANNER_TEXT1:'Workday Learning is now live! Workday Learning is the hub for all things related to learning and development. Go here to browse available on-demand opportunities, view your transcript of past training, and register for upcoming live training.'
    },
    "Español": {
        TITLE_HEADING: "Página de inicio del Portal del craft",
        TITLE_BODY_LINE1: "Accede a programas de capacitación, lee información importantey revisa los videos más recientes de DPR.",
        LEARNING_CARD_HEADING: "Aprendizaje de Workday: programas de aprendizaje específicos",
        WORKDAY_CARD_HEADING: "Mi DPR: detalles sobre el empleo y los beneficios",
        DPR_BENEFITS_TITLE:"Beneficos de DPR",
        DPR_BENEFITS_CARD_HEADING: "Beneficios de DPR - Informacion del plan de beneficios de DPR disponible aqui",
        ADP_CARD_HEADING: "ADP: Acceda a sus talones de pago y W2",
        VIEW_GUIDES_BUTTON: "Ver guías",
        SITE_REDIRECT_BUTTON: "Ir al sitio",
        YOUTUBE_TEXT1: "Ponte al día con",
        YOUTUBE_TEXT2: "Craft de DPR Craft on Youtube",
        HELP_CARD_HEADING: "¿Tienes alguna otra pregunta?",
        HELP_CARD_BODY: `Busca un recurso local que te pueda ayudar.`,
        HELP_CARD_BUTTON: "Contactos",
        EMPLOYEEGUIDEBOOK_CARD_HEADING: "DPR Guía del empleado",
        EMPLOYEEGUIDEBOOK_CARD_BODY: `Políticas y pautas actuales del DPR para herramientas y recursos.`,
        EMPLOYEEGUIDEBOOK_CARD_BUTTON: "Vista",
        LINK: employeeGuideBookSP,
        BANNER_TEXT1:'Workday Learning ya está disponible. Workday Learning es el centro de todo lo relacionado con el aprendizaje y el desarrollo: aquí puedes para buscar oportunidades disponibles a pedido, ver su expediente académico de capacitaciones anteriores completadas y registrarse para las próximas capacitaciones en vivo.',
    }
}