import * as React from "react"
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import {Route} from "react-router-dom";

const PublicRouter = (props) => {
    let isAuthorized = useSelector(state => state.toggleAuthReducer.authorized);
    
    if(isAuthorized){
        return <Redirect to="/home"/>;
    }

    return <Route exact={props.exact} path={props.path} component={props.component} />
}

export default PublicRouter;