import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { welcomeText } from "../constants/welcomeText";
import authentication from "./authentication";
import { config } from "../constants/config";
import welcomeBackDrop from "../images/welcomeBackDrop.jpg";
import dprLogo from "../images/dprLogo.png";
import iconHelp from "../images/iconHelp.png";
import iconBook from "../images/iconBook.png";
import "../styleSheets/welcome.scss";

import howToGuide from "../documents/HowToGuide.pdf"
import howToGuideSP from "../documents/HowToGuideSP.pdf"


const Welcome = () => {

    const [redirectionUrl, setRedirectionURL] = useState("");
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);

    const login = async () => {
        try {
            await authentication.userAgentApplication.loginRedirect({ scopes: config.scopes, prompt: "select_account" });
        } catch (err) {
            //display error
            console.log(err);
        }
    }

    if (redirectionUrl != "") {
        return <Redirect push to={redirectionUrl} />
    }

    const redirectToContacts = (event) => {
        switch (event.target.id) {
            case "helpGuideButton": setRedirectionURL("/contacthelp");
                break;
            default:
                console.log("I'm default")
        }
    }

    return <div className="welcomeRootContainer">
        <div className="signInContainer">
            <Image src={welcomeBackDrop} fluid className="welcomeBackDropImage" />
            <Card className="welcomeCardContainer">
                <Image src={dprLogo} className="welcomeDRPLogo" />
                <Card.Body>
                    <Card.Text>
                        <strong>{welcomeText[selectedLanguage].HEADING_LINE1}</strong><br /><span>{welcomeText[selectedLanguage].HEADING_LINE2}</span>
                    </Card.Text>
                    <Button variant="primary" onClick={login}>{welcomeText[selectedLanguage].SIGN_IN_BUTTON}</Button>
                </Card.Body>
            </Card>
        </div>
        <Container>
            <Row className="justify-content-center">
                <Col xs="12" md="5" className="assistContainer">
                    <div className="iconContainer">
                        <Image src={iconBook} className="icon iconBook" />
                    </div>
                    <h6><strong>{welcomeText[selectedLanguage].ACCESS_ASSIST_HEADING}</strong></h6>
                    <p>
                        {welcomeText[selectedLanguage].ACCESS_ASSIST_BODY}
                    </p>
                    <a href={selectedLanguage == "English" ? howToGuide : howToGuideSP} target="_blank">
                        <Button variant="outline-secondary">{welcomeText[selectedLanguage].ACCESS_ASSIST_BUTTON}</Button>
                    </a>
                </Col>
                <Col xs="12" md="5" className="assistContainer">
                    <div className="iconContainer">
                        <Image src={iconHelp} className="icon iconHelp" />
                    </div>
                    <h6><strong>{welcomeText[selectedLanguage].OTHER_ASSIST_HEADING}</strong></h6>
                    <p>
                        {welcomeText[selectedLanguage].OTHER_ASSIST_BODY}
                    </p>
                    <Button variant="outline-secondary" id="helpGuideButton" onClick={redirectToContacts}>
                        {welcomeText[selectedLanguage].OTHER_ASSIST_BUTTON}</Button>
                </Col>
            </Row>
        </Container>
    </div>
}

export default Welcome;
