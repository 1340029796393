import adpPaystubsEmployeeReferenceGuide from "../documents/adp/ADPPaystubsEmployeeReferenceGuide_Eng.pdf"
import adpPaystubsEmployeeReferenceGuideSP from "../documents/adp/ADPPaystubsEmployeeRev1.0_Spanish.pdf"
import adpW2EmployeeReferenceGuide from "../documents/adp/ADPW2EmployeeReferenceGuide_Eng.pdf"
import adpW2EmployeeReferenceGuideSP from "../documents/adp/ADPW2EmployeeReferenceGuideRev1.0_Spanish.pdf"


export const adpText = {
  "English": {
      TITLE_HEADING: "How to Guides for ADP",
      TITLE_BODY: "Access the guides below. Or go straight to",
      GUIDE_HEADING: "How to Guides for ADP:",
      GUIDE_BODY1: `ADP is used to view and access your paystubs and W2. Please refer to guides below to get started:`,
      LINKS: [{
          TEXT: "How to Access Your Paystubs in ADP.",
          LINK: adpPaystubsEmployeeReferenceGuide
      }, {
          TEXT: "How to Access Your W2 in ADP.",
          LINK: adpW2EmployeeReferenceGuide
      }],
      SITE_REDIRECT_BUTTON: "GO TO SITE",
      HOME_REDIRECT_BUTTON: "BACK TO PORTAL HOME"
  },
  "Español": {
      TITLE_HEADING: "Guías prácticas para ADP",
      TITLE_BODY: "Accede a las guías a continuación. O dirígete directamente a",
      GUIDE_HEADING: "Guías prácticas para ADP:",
      GUIDE_BODY1: `ADP se utiliza para ver y acceder a sus talones de pago y W2. Consulta las guías a continuación para comenzar:`,
      LINKS: [{
          TEXT: "Cómo acceder a sus talones de pago en ADP.",
          LINK: adpPaystubsEmployeeReferenceGuideSP
      }, {
          TEXT: "Cómo acceder a su W2 en ADP.",
          LINK: adpW2EmployeeReferenceGuideSP
      }],
      SITE_REDIRECT_BUTTON: "IR AL SITIO",
      HOME_REDIRECT_BUTTON: "VOLVER A INICIO DEL PORTAL"
  }
}