import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';

import CentralRouter from "./containers/routers/centralRouter";
import store from "./store/store";

const App = (props) => {
    return <Provider store={store}>
        <CentralRouter />
    </Provider>;
}

let mountNode = document.getElementById("root");
ReactDOM.render(<App />, mountNode);
