import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import AuthenticatedRouter from "./authenticatedRouter";
import PublicRouter from "./publicRouter";
import PageNotFound from "../../components/404";
import Welcome from "../../components/welcome";
import Home from "../../components/home";
import Workday from "../../components/workday";
import Adp from "../../components/adp";
import Navigation from "../navigation";
import Footer from "../../components/footer";
import Contacts from "../../components/contacts";
import "../../styleSheets/root.scss";
import authentication from "../../components/authentication";
import { authToggleAction } from "../../store/actions/authActions";


const CentralRouter = () => {

    let dispatch = useDispatch();
    let [authCheck, toggleAuthCheck] = useState(false);

    useEffect(() => {
        trimUrl();
        async function getUserData() {
            let account = authentication.userAgentApplication.getAccount();
            if (account) {
                try {
                    dispatch(authToggleAction(true));
                } catch (error) {
                    //dispatch error here
                    dispatch(authToggleAction(false));
                }
            }
            toggleAuthCheck(true);
        }
        getUserData();

    }, []);

    const trimUrl = () => {
        let route = window.location.pathname;
        if (route.lastIndexOf("/") == route.length - 1) {
            let queryParams = window.location.search;
            route = route.substring(0, route.length - 1);
            route = queryParams.length == 0 ? route : route + queryParams;
            window.history.replaceState({}, "", route);
        }
    }
    if (authCheck) {
        return <div className="centralContainer">
            <Navigation />
            <BrowserRouter>
                <Switch>
                    <AuthenticatedRouter exact={true} path="/home" component={Home} />

                    <AuthenticatedRouter exact={true} path="/workday" component={Workday} />
                    <AuthenticatedRouter exact={true} path="/adp" component={Adp} />
                    <AuthenticatedRouter exact={true} path="/contacts" component={Contacts} />
                    <PublicRouter exact={true} path="/contacthelp" component={Contacts} />
                    <PublicRouter exact={true} path="/" component={Welcome} />
                    <Route path="/*" component={PageNotFound} />
                </Switch>
            </BrowserRouter>
            <Footer />
        </div>
    } else {
        return null;
    }
}

export default CentralRouter;
