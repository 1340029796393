import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import "../styleSheets/workday.scss";
import workdayLogo from "../images/workdayLogo.png";
import linkBlack from "../images/linkBlack.png";
import linkWhite from "../images/linkWhite.png";
import { updateComponentAction } from "../store/actions/componentActions";
import { components } from "../constants/components";
import { workdayText } from "../constants/workdayText";
import arrowBack from "../images/arrowBack.png";


const Workday = () => {

    const dispatch = useDispatch();
    const [redirectionUrl, setRedirectionURL] = useState("");
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updateComponentAction(components.workday.componentName));
    }, []);

    if (redirectionUrl != "") {
        return <Redirect push to={redirectionUrl} />
    }

    const redirect = () => {
        setRedirectionURL("/home");
    }

    return <Container fluid className="justify-content-center workdayContainer">
        <Row className="workdayTitleContainer">
            <Col xs="12">
                <h2>{workdayText[selectedLanguage].TITLE_HEADING}</h2>
                <p>{workdayText[selectedLanguage].TITLE_BODY} <a href="https://myapps.microsoft.com/signin/Workday/234a1a24502142e5a087335f9006d64b">Workday <Image src={linkBlack} className="linkLogo" /></a></p>
            </Col>
        </Row>
        <Row className="guideContainer justify-content-center">
            <Col xs="10" >
                <h6>{workdayText[selectedLanguage].GUIDE_HEADING}</h6>
                <p>
                    <span>{workdayText[selectedLanguage].GUIDE_BODY1}</span>
                    <br />
                    <ul>
                        {
                            workdayText[selectedLanguage].LINKS.map((element, index) => {
                                return <li key={index}>
                                    <a href={element.LINK} target="_blank" className="guideLinks"><strong>{element.TEXT}</strong></a>
                                    <br />
                                </li>
                            })
                        }
                    </ul>
                </p>
            </Col>
        </Row>
        <Row className="justify-content-center workplaceCardContainer">
            <Col xs="12">
                <Image src={workdayLogo} className="workplaceImage" />
            </Col>
            <Col xs="12">
                <a href="https://myapps.microsoft.com/signin/Workday/234a1a24502142e5a087335f9006d64b"><Button className="redirectButton">{workdayText[selectedLanguage].SITE_REDIRECT_BUTTON} <Image src={linkWhite} className="linkLogo" /></Button></a>
            </Col>
        </Row>
        <Row className="redirectContainer">
            <Col>
                <Button variant="outline-secondary" onClick={redirect}><Image src={arrowBack} /> {workdayText[selectedLanguage].HOME_REDIRECT_BUTTON}</Button>
            </Col>
        </Row>
    </Container>
}

export default Workday;