import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';

const PageNotFound = () =>{
    let isAuthorized = useSelector(state => state.toggleAuthReducer.authorized);

    if(!isAuthorized){
        return <Redirect to="/"/>;
    }else{
        return <Redirect to="/home"/>;
    }
}

export default PageNotFound;