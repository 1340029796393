import howToChangeYourBeneficiary from "../documents/workday/HowToChangeYourBeneficiary.pdf"
import howToChangeYourBeneficiarySP from "../documents/workday/HowToChangeYourBeneficiarySP.pdf"
import howToChangeYourBenefits from "../documents/workday/HowToChangeYourBenefits.pdf"
import howToChangeYourBenefitsSP from "../documents/workday/HowToChangeYourBenefitsSP.pdf"
import howToChangeYourContactInformation from "../documents/workday/HowToChangeYourContactInformation.pdf"
import howToChangeYourContactInformationSP from "../documents/workday/HowToChangeYourContactInformationSP.pdf"
import howToChangeYourDependentInformation from "../documents/workday/HowToChangeYourDependentInformation.pdf"
import howToChangeYourDependentInformationSP from "../documents/workday/HowToChangeYourDependentInformationSP.pdf"
import howToChangeYourEmergencyContactInformation from "../documents/workday/HowToChangeYourEmergencyContactInformation.pdf"
import howToChangeYourEmergencyContactInformationSP from "../documents/workday/HowToChangeYourEmergencyContactInformationSP.pdf"
import howToChangeYourFederalStateWithholds from "../documents/workday/HowToChangeYourFederalStateWithholds.pdf"
import howToChangeYourFederalStateWithholdsSP from "../documents/workday/HowToChangeYourFederalStateWithholdsSP.pdf"
import howToChangeYourLegalName from "../documents/workday/HowToChangeYourLegalName.pdf"
import howToChangeYourLegalNameSP from "../documents/workday/HowToChangeYourLegalNameSP.pdf"
import howToChangeYourPhoto from "../documents/workday/HowToChangeYourPhoto.pdf"
import howToChangeYourPhotoSP from "../documents/workday/HowToChangeYourPhotoSP.pdf"
import howToChangeYourRelocationPreferences from "../documents/workday/HowToChangeYourRelocationPreferences.pdf"
import howToChangeYourRelocationPreferencesSP from "../documents/workday/HowToChangeYourRelocationPreferencesSP.pdf"
import howToViewPaidCareLeaveAndVacationTime from "../documents/workday/HowToViewPaidCareLeaveAndVacationTime.pdf"
import howToViewPaidCareLeaveAndVacationTimeSP from "../documents/workday/HowToViewPaidCareLeaveAndVacationTimeSP.pdf"


export const workdayText = {
    "English": {
        TITLE_HEADING: "How to Guides for Workday",
        TITLE_BODY: "Access the guides below. Or go straight to",
        GUIDE_HEADING: "How to Guides for Workday:",
        GUIDE_BODY1: ` Workday (MyDPR) is used to view and manage benefits and employee details.
                        Please refer to guides below to get started:`,
        LINKS: [
            {
                TEXT: "How to Change Your Beneficiary Information in Workday.",
                LINK: howToChangeYourBeneficiary
            },
            {
                TEXT: "How to Change Your Benefits in Workday.",
                LINK: howToChangeYourBenefits
            },
            {
                TEXT: "How to Change Your Contact Information in Workday.",
                LINK: howToChangeYourContactInformation
            },
            {
                TEXT: "How to Chnage Your Dependent Information in Workday.",
                LINK: howToChangeYourDependentInformation
            },
            {
                TEXT: "How to Change Your Emergency Contact Information in Workday.",
                LINK: howToChangeYourEmergencyContactInformation
            },
            {
                TEXT: "How to Change Your Federal and State Withholdings in Workday.",
                LINK: howToChangeYourFederalStateWithholds
            },
            {
                TEXT: "How to Change Your Legal Name in Workday.",
                LINK: howToChangeYourLegalName
            },
            {
                TEXT: "How to Change Your Photo in Workday.",
                LINK: howToChangeYourPhoto
            },
            {
                TEXT: "How to Enter Relocation Preferences in Workday.",
                LINK: howToChangeYourRelocationPreferences
            },
            {
                TEXT: "How to View Your PCL & Vacation Time in Workday.",
                LINK: howToViewPaidCareLeaveAndVacationTime
            }
        ],
        SITE_REDIRECT_BUTTON: "GO TO SITE",
        HOME_REDIRECT_BUTTON: "BACK TO PORTAL HOME"
    },
    "Español": {
        TITLE_HEADING: "Guías prácticas de Workday",
        TITLE_BODY: "Accede a las guías a continuación. O dirígete directamente a",
        GUIDE_HEADING: "Guías prácticas de Workday:",
        GUIDE_BODY1: `Workday (Mi DPR) se usa para ver y gestionar los beneficios y 
                        detalles de los empleados. Consulta las guías a continuación para comenzar:`,
        LINKS: [
            {
                TEXT: "Cómo cambiar la información de tus beneficiarios en Workday.",
                LINK: howToChangeYourBeneficiarySP
            },
            {
                TEXT: "Cómo cambiar tus beneficios en Workday.",
                LINK: howToChangeYourBenefitsSP
            },
            {
                TEXT: "Cómo cambiar tu información de contacto en Workday.",
                LINK: howToChangeYourContactInformationSP
            },
            {
                TEXT: "Cómo cambiar la información de tus dependientes en Workday.",
                LINK: howToChangeYourDependentInformationSP
            },
            {
                TEXT: "Cómo cambiar tu información de contacto de emergencia en Workday.",
                LINK: howToChangeYourEmergencyContactInformationSP
            },
            {
                TEXT: "Cómo cambiar tus retenciones federales y estatales en Workday.",
                LINK: howToChangeYourFederalStateWithholdsSP
            },
            {
                TEXT: "Cómo cambiar tu nombre legal en Workday.",
                LINK: howToChangeYourLegalNameSP
            },
            {
                TEXT: "Cómo cambiar tu foto en Workday.",
                LINK: howToChangeYourPhotoSP
            },
            {
                TEXT: "Cómo ingresar preferencias de reubicación en Workday.",
                LINK: howToChangeYourRelocationPreferencesSP
            },
            {
                TEXT: "Cómo ver tu PCL y tiempo de vacaciones en Workday.",
                LINK: howToViewPaidCareLeaveAndVacationTimeSP
            }
        ],
        SITE_REDIRECT_BUTTON: "IR AL SITIO",
        HOME_REDIRECT_BUTTON: "VOLVER A INICIO DEL PORTAL"
    }
}