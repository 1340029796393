//Constants file for the redux store

//Dependencies
import {languages} from "../constants/languageConstants";

export const storePrefLang = {
    preferredLanguage : languages.EN
}

export const auth = {
    authorized : false
}

export const userObject = {
    displayName : "",
    givenName : "",
    surName : ""
}

export const errorObject = {
    errorMessage : ""
}

export const actionTypes = {
    UPDATE_LANGUAGE : "UPDATELANGUAGE",
    TOGGLE_AUTHORIZED : "TOGGLEAUTHORIZED",
    UPDATE_USER_DATA : "UPDATEUSERDATA",
    UPDATE_ERROR_MSG : "UPDATEERRORMSG",
    UPDATE_COMPONENT: "UPDATECOMPONENT"
}

export const component = "";