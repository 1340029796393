import { createStore, combineReducers } from "redux";
import {updateUserReducer} from "./reducers/userReducer";
import {toggleAuthReducer} from "./reducers/authReducer";
import {updateErrorReducer} from "./reducers/errorReducer";
import {alterLanguageReducer} from "./reducers/languageReducer";
import {updateComponentReducer} from "./reducers/componentReducer";

const combinedReducer = combineReducers({
        updateUserReducer,
        toggleAuthReducer,
        updateErrorReducer,
        alterLanguageReducer,
        updateComponentReducer
    });

const store = createStore(combinedReducer);

export default store;