const arizona = "Arizona"
const atlanta = "Atlanta"
const austin = "Austin"
const bayArea = "Bay Area"
const boston = "Boston"
const charlotteGreenville = "Charlotte/Greenville"
const dallas = "Dallas"
const eigAustin = "EIG Austin"
const eigCharlotte = "EIG Charlotte"
const eigDallas = "EIG Dallas"
const eigRaleighDurham = "EIG Raleigh-Durham"
const eigSacramento = "EIG Sacramento"
const ftLauderdale = "Ft. Lauderdale"
const houston = "Houston"
const midAtlantic = "Mid-Atlantic"
const nashville = "Nashville"
const newJersey = "New Jersey"
const orlando = "Orlando"
const raleighDurham = "Raleigh-Durham"
const richmond = "Richmond"
const sacramento = "Sacramento"
const sanDiego = "San Diego"
const seattle = "Seattle"
const southernCalifornia = "Southern California"
const tampaFl = "Tampa FL"

const craftCoordinator = "Craft Coordinator"
const craftTalentPartner = "Craft Talent Partner"


export const contactInformation = {
  "Arizona": [
    {
      business_unit: arizona,
      role: craftCoordinator,
      name: "Andres Sanchez",
      email: "andress@dpr.com",
      cell: "(480) 272-3388"
    },
    {
      business_unit: arizona,
      role: craftTalentPartner,
      name: "Andres Sanchez",
      email: "andress@dpr.com",
      cell: "(480) 272-3388"
    }
  ],
  "Atlanta": [
    {
      business_unit: atlanta,
      role: craftCoordinator,
      name: "Alexa Van de Water",
      email: "alexandrav@dpr.com",
      cell: "(305) 690-2891"
    },
    {
      business_unit: atlanta,
      role: craftTalentPartner,
      name: "Alexa Van de Water",
      email: "alexandrav@dpr.com",
      cell: "(305) 690-2891"
    }
  ],
  "Austin": [
    {
      business_unit: austin,
      role: craftCoordinator,
      name: "Courtney Jones",
      email: "courtneyj@dpr.com",
      cell: "(916) 627-5990" 
    },
    {
      business_unit: austin,
      role: craftCoordinator,
      name: "Kalee McCartney",
      email: "kaleem@dpr.com",
      cell: "(512) 318-3161"
    },
    {
      business_unit: austin,
      role: craftTalentPartner,
      name: "Glendy Rios",
      email: "glendyr@dpr.com",
      cell: "(979) 418-0034"
    }
  ],
  "Bay Area": [
    {
      business_unit: bayArea,
      role: craftCoordinator,
      name: "Amber Spence (SPW)",
      email: "ambersp@dpr.com",
      cell: "(650) 868-9982"
    },
    {
      business_unit: bayArea,
      role: craftCoordinator,
      name: "Theresa Watt (CW & GC)",
      email: "theresaw@dpr.com",
      cell: "(650) 533-6457"
    },
    {
      business_unit: bayArea,
      role: craftCoordinator,
      name: "Patricia Kostiew-Yale (Demo)",
      email: "patriciak@dpr.com",
      cell: "(650) 255-3346"
    },
    {
      business_unit: bayArea,
      role: craftTalentPartner,
      name: "Mirian Martinez",
      email: "mirianm@dpr.com",
      cell: "(650) 208-7642"
    },
    {
      business_unit: bayArea,
      role: craftTalentPartner,
      name: "George Achica",
      email: "georgea@dpr.com",
      cell: "(650) 333-4697"
    }
  ],
  "Boston": [
    {
      business_unit: boston,
      role: craftCoordinator,
      name: "Leidi Castro",
      email: "leidic@dpr.com",
      cell: "(804) 229-9326"
    }
  ],
  "Charlotte/Greenville": [
    {
      business_unit: charlotteGreenville,
      role: craftCoordinator,
      name: "Rosa Gil",
      email: "rosag@dpr.com",
      cell: "(980) 266-2167"
    },
    {
      business_unit: charlotteGreenville,
      role: craftTalentPartner,
      name: "Bruce Worcester",
      email: "brucew@dpr.com",
      cell: "(919) 614-4672"
    }
  ],
  "Dallas": [
    {
      business_unit: dallas,
      role: craftCoordinator,
      name: "Mariana Caro-Valtierra",
      email: "marianac@dpr.com",
      cell: "(214) 952-4041"
    },
    {
      business_unit: dallas,
      role: craftTalentPartner,
      name: "Teresa Aragon",
      email: "teresaa@dpr.com",
      cell: "(214) 608-8906"
    }
  ],
  "EIG Austin": [
    {
      business_unit: eigAustin,
      role: craftCoordinator,
      name: "Mary Dominguez",
      email: "maryd@eigllc.com",
      cell: "(512) 676-1913"
    }
  ],
  "EIG Charlotte": [
    {
      business_unit: eigCharlotte,
      role: craftCoordinator,
      name: "Leslie Taylor",
      email: "leslieta@eigllc.com",
      cell: "(980) 257-5465"
    }
  ],
  "EIG Dallas": [ 
    {
      business_unit: eigDallas,
      role: craftCoordinator,
      name: "Georgina (Gina) Martinez",
      email: "ginam@eigllc.com",
      cell: "(469) 236-5975"
    }
  ],
  "EIG Raleigh-Durham": [ 
    {
      business_unit: eigRaleighDurham,
      role: craftCoordinator,
      name: "Alyssa Fenty",
      email: "alyssaf@eigllc.com",
      cell: "(919) 257-0430"
    }
  ],
  "EIG Sacramento": [
    {
      business_unit: eigSacramento,
      role: craftCoordinator,
      name: "Kassandra Orr",
      email: "kassandrao@eigllc.com",
      cell: "(916) 597-3231"
    },
    {
      business_unit: eigSacramento,
      role: craftCoordinator,
      name: "Kevin Sanabria",
      email: "kevinsa@eigllc.com",
      cell: "(916) 201-3371"
    }
  ],
  "Ft. Lauderdale": [
    {
      business_unit: ftLauderdale,
      role: craftCoordinator,
      name: "Iris Ellis",
      email: "irise@dpr.com",
      cell: "(954) 397-8049"
    },
    {
      business_unit: ftLauderdale,
      role: craftTalentPartner,
      name: "Valerie Diaz-Harbich",
      email: "valerieh@dpr.com",
      cell: "(954) 405-6723"
    }
  ],
  "Houston": [
    {
      business_unit: houston,
      role: craftCoordinator,
      name: "Karla Gonzalez",
      email: "karlag@dpr.com",
      cell: "(713) 256-5181"
    },
    {
      business_unit: houston,
      role: craftTalentPartner,
      name: "Karla Gonzalez",
      email: "karlag@dpr.com",
      cell: "(713) 256-5181"
    }
  ],
  "Mid-Atlantic": [
    {
      business_unit: midAtlantic,
      role: craftCoordinator,
      name: "Lady Montano",
      email: "ladym@dpr.com",
      cell: "(571) 512-2722"
    },
  ],
  "Nashville": [
    {
      business_unit: nashville,
      role: craftCoordinator,
      name: "Shelby Turner",
      email: "shelbytu@dpr.com",
      cell: "(704) 891-8427"
    },
    {
      business_unit: nashville,
      role: craftTalentPartner,
      name: "Halston Abbott",
      email: "halstona@dpr.com",
      cell: "(615) 979-3256"
    },
    {
      business_unit: nashville,
      role: craftTalentPartner,
      name: "Ronald Gonzalez",
      email: "ronaldg@dpr.com",
      cell: "(615) 906-3725"
    }
  ],
  "New Jersey": [
    {
      business_unit: newJersey,
      role: craftCoordinator,
      name: "Leidi Castro",
      email: "leidic@dpr.com",
      cell: "(804) 229-9326"
    }
  ],
  "Orlando": [ 
    {
      business_unit: orlando,
      role: craftCoordinator,
      name: "Patricia Flores",
      email: "patriciaf@dpr.com",
      cell: "(407) 784-0457"
    },
    {
      business_unit: orlando,
      role: craftTalentPartner,
      name: "Monicia Echevarria",
      email: "monicae@dpr.com",
      cell: "(407) 269-3063"
    }
  ],
  "Raleigh-Durham": [
    {
      business_unit: raleighDurham,
      role: craftCoordinator,
      name: "Sandi Haithcock",
      email: "sandih@dpr.com",
      cell: "(919) 224-9344"
    },
    {
      business_unit: raleighDurham,
      role: craftTalentPartner,
      name: "Bruce Worcester",
      email: "brucew@dpr.com",
      cell: "(919) 614-4672"
    }
  ],
  "Richmond": [
    {
      business_unit: richmond,
      role: craftCoordinator,
      name: "Leidi Castro",
      email: "leidic@dpr.com",
      cell: "(804) 229-9326"
    },
    {
      business_unit: richmond,
      role: craftTalentPartner,
      name: "Andy Vanover",
      email: "andyv@dpr.com",
      cell: "(804) 380-1015"
    }
  ],
  "Sacramento": [
    {
      business_unit: sacramento,
      role: craftCoordinator,
      name: "Amber Spence",
      email: "ambersp@dpr.com",
      cell: "(650) 868-9982"
    },
    {
      business_unit: sacramento,
      role: craftTalentPartner,
      name: "Jonnie Johnson",
      email: "jonniej@dpr.com",
      cell: "(916) 826-7357"
    }
  ],
  "San Diego": [
    {
      business_unit: sanDiego,
      role: craftCoordinator,
      name: "Shannon Anderson",
      email: "shannona@dpr.com",
      cell: "(650) 670-2207"
    },
    {
      business_unit: sanDiego,
      role: craftCoordinator,
      name: "Mariela Lara",
      email: "marielal@dpr.com",
      cell: "(619) 455-6992"
    },
    {
      business_unit: sanDiego,
      role: craftTalentPartner,
      name: "Maryam Pejmannia",
      email: "maryamp@dpr.com",
      cell: "(915) 731-9533"
    }
  ],
  "Seattle": [
    {
      business_unit: seattle,
      role: craftCoordinator,
      name: "Rick Mueller",
      email: "rickm@dpr.com",
      cell: "(206) 949-2829"
    },
    {
      business_unit: seattle,
      role: craftTalentPartner,
      name: "Rick Mueller",
      email: "rickm@dpr.com",
      cell: "(206) 949-2829"
    }
  ],
  "Southern California": [
    {
      business_unit: southernCalifornia,
      role: craftCoordinator,
      name: "Sandy Brooks",
      email: "sandrab@dpr.com",
      cell: "(949) 230-7712"
    },
    {
      business_unit: southernCalifornia,
      role: craftTalentPartner,
      name: "Jose Garcia",
      email: "joseg@dpr.com",
      cell: "(949) 524-5394"
    }
  ],
  "Tampa FL": [ 
    {
      business_unit: tampaFl,
      role: craftCoordinator,
      name: "Rosalia Chavez",
      email: "rosaliac@dpr.com",
      cell: "(813) 557-8564"
    },
    {
      business_unit: tampaFl,
      role: craftTalentPartner,
      name: "Monicia Echevarria",
      email: "monicae@dpr.com",
      cell: "(407) 269-3063"
    }
  ],
}
