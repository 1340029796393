export const welcomeText = {
    "English" : {
        HEADING_LINE1 : "WELCOME TO THE",
        HEADING_LINE2 : "Craft Portal",
        SIGN_IN_BUTTON : "SIGN IN",
        ACCESS_ASSIST_HEADING : "How To Guide",
        ACCESS_ASSIST_BODY : `Learn more about how to log on 
                              to the craft portal.`,
        ACCESS_ASSIST_BUTTON : "How To Guide",
        OTHER_ASSIST_HEADING : "Additional Questions?",
        OTHER_ASSIST_BODY : ` Find a local resource who can help.`,
        OTHER_ASSIST_BUTTON : "Contacts",
    },
    "Español" : {
        HEADING_LINE1 : "BIENVENIDO AL",
        HEADING_LINE2 : "Portal del craft",
        SIGN_IN_BUTTON : "Iniciar sesión",
        ACCESS_ASSIST_HEADING : "Guía práctica",
        ACCESS_ASSIST_BODY : `Obtén más información sobre cómo iniciar sesión en el Portal del craft.`,
        ACCESS_ASSIST_BUTTON : "Guía práctica",
        OTHER_ASSIST_HEADING : "¿Tienes alguna otra pregunta?",
        OTHER_ASSIST_BODY : ` Busca un recurso local que te pueda ayudar.`,
        OTHER_ASSIST_BUTTON : "Contactos",
    }
} 