import React from "react";
import { useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {text} from "../constants/footer";

import "../styleSheets/footer.scss";

const footer = () => {
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);
    return  <Container fluid className="footerContainer">
                <Row>
                    <Col xs="12" md="6" className="copyrightContainer">
                        {text[selectedLanguage].COPYRIGHT}
                    </Col>
                    <Col  xs="12" md="6" className="policyContainer">
                        <a href="" className="privaryPolicy">{text[selectedLanguage].PRIVACY_POLICY}</a>
                    </Col>
                </Row>
            </Container>
}

export default footer;