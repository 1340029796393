//dependencies
import {actionTypes, userObject} from '../storeConstants';


export const updateUserReducer = (state = {...userObject},actions) => {

    switch(actions.type){

        case actionTypes.UPDATE_USER_DATA : 

                return {...actions.payload};
                break;
        default : 
                return {...state};
                break;         
    }
}