import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import arrowBack from "../images/arrowBack.png";
import { updateComponentAction } from "../store/actions/componentActions";
import { components } from "../constants/components";
import { learningText } from "../constants/learningText";
import { contactInformation } from "../constants/contactInformation";
import ContactCard from "./contactCard";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import Card from "react-bootstrap/Card";
import "../styleSheets/contacts.scss";


const Contacts = () => {

  const dispatch = useDispatch();
  const [redirectionUrl, setRedirectionURL] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedContacts, setSearchedContacts] = useState([]);
  const [dprBusinessUnits, setDprBusinessUnits] = useState()
  const [dprContacts, setDprContacts] = useState()

  let selectedLanguage = useSelector((state) => state.alterLanguageReducer.preferredLanguage);


  useEffect(() => {
    const businessUnitList = []
    for (const bu in contactInformation) {
      businessUnitList.push(bu)
    }
    setDprBusinessUnits(businessUnitList)
    contactList()
  }, []);

  if (redirectionUrl != "") {
    return <Redirect push to={redirectionUrl} />;
  }

  const redirectToHome = () => {
    setRedirectionURL("/home");
  };

  const contactList = () => {
    const contacts = []
    for (const bu in contactInformation) {
      contacts.push(header(bu))
    }
    setDprContacts(contacts)
  }

  const header = (props) => {
    return (
      <Col xs="12" sm="6" md="6" lg="4" xl="3" key={props}>
        <Card className="contactCard" >
          <Card.Header className="text-white"> {props} </Card.Header>
          {contactInformation[props].map((person, index) => {
            return (<ContactCard key={index} person={person} />)
          })}
        </Card>
      </Col>
    )
  }

  const searchByLocation = (e) => {
    const businessUnits = []
    dprBusinessUnits.forEach(bu => {
      if (bu.toLowerCase().includes(searchText.toLowerCase())) {
        businessUnits.push(header(bu))
      }
    })
    setSearchedContacts(businessUnits)
  };

  const resetInput = () => {
    setSearchText("")
    setSearchedContacts([])
  }

  return (
    <Container fluid className="contactsContainer">
      <Row className="justify-content-center titleContainer">
        <Col lg="4">
          <InputGroup className="justify-content-center searchContainer">
            <FormControl placeholder="Search by Location" aria-label="two button addons"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)} />
            <Button variant="primary" className="buttons" value={searchText} onClick={searchByLocation}>SEARCH</Button>
            <Button variant="primary" className="buttons" onClick={resetInput}>RESET</Button>
          </InputGroup>
        </Col>
      </Row>

      <Row className="justify-content-center cardContainer">
        {searchedContacts.length === 0 ? dprContacts : searchedContacts}
      </Row>

      <Row className="redirectContainer">
        <Col xs="12">
          <Button variant="outline-secondary" onClick={redirectToHome}>
            <Image src={arrowBack} />
            {learningText[selectedLanguage].HOME_REDIRECT_BUTTON}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Contacts;
