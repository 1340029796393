//dependencies
import {auth,actionTypes} from '../storeConstants';


export const toggleAuthReducer = (state = {...auth},actions) => {

    switch(actions.type){

        case actionTypes.TOGGLE_AUTHORIZED : 
                return {authorized : actions.payload};
                break;
        default : 
                return {...state};
                break;         
    }
}