import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

import { languages } from "../constants/languageConstants";
import { updateLanguageAction } from "../store/actions/languageActions";
import "../styleSheets/navigation.scss";
import authentication from "../components/authentication";
import dprLogo from "../images/dprLogo.png";
import { components } from "../constants/components";
import globeBlack from "../images/globeBlack.png";
import globeWhite from "../images/globeWhite.png";
import linkBlack from "../images/linkBlack.png";
import { navigationText } from "../constants/navigationText";
import { homeText } from "../constants/homeText";


const Navigation = () => {
    let isAuthorized = useSelector(state => state.toggleAuthReducer.authorized);
    let selectedLanguage = useSelector(state => state.alterLanguageReducer.preferredLanguage);
    let activeComponent = useSelector(state => state.updateComponentReducer);
    let dispatch = useDispatch();

    useEffect(() => {
        let language = localStorage.getItem("language");
        language && dispatch(updateLanguageAction(language));
    }, []);

    useEffect(() => {
        if (isAuthorized) {
            let time;

            document.onmousemove = resetTimer;
            document.onkeypress = resetTimer;

            function resetTimer() {
                clearTimeout(time);
                time = setTimeout(logout, 300000);
            };
        }
    }, [isAuthorized]);

    const buildDropDownList = () => {
        let dropDownList = Object.keys(languages).map((key, index) => {
            return <NavDropdown.Item key={index} id={languages[key]} onClick={alterPrefLanguage}>{languages[key]}</NavDropdown.Item>
        });

        return dropDownList;
    }

    const alterPrefLanguage = (event) => {
        let language = event.currentTarget.id;
        if (language != selectedLanguage) {
            dispatch(updateLanguageAction(language));
            localStorage.setItem("language", language);
        }
    }

    const buildNavLinks = () => {
        const navLinks = Object.keys(components).map((key, index) => {
            return <Nav.Link
                key={index}
                href={components[key].url}
                id={components[key].componentName}
                className={activeComponent == components[key].componentName ? "active" : "inactive"}>
                {components[key].title[selectedLanguage] ? components[key].title[selectedLanguage] : components[key].title}
            </Nav.Link>
        });

        return navLinks;
    }

    const logout = () => {
        authentication.userAgentApplication.logout();
    }

    if (!isAuthorized) {
        return <Navbar expand="lg" className="preAuthNav">
            <NavDropdown title={<span><Image src={globeWhite} className="globeLogo" /> {selectedLanguage}</span>} id="basic-nav-dropdown">
                {buildDropDownList()}
            </NavDropdown>
        </Navbar>
    } else {
        return <Navbar expand="lg" bg="light" className="postAuthNav">
            <Navbar.Brand><Image src={dprLogo} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <NavDropdown title={<span><Image src={globeBlack} className="globeLogo" /> {selectedLanguage}</span>} id="basic-nav-dropdown">
                        {buildDropDownList()}
                    </NavDropdown>
                </Nav>
                <Nav>
                    {buildNavLinks()}
                    <Nav.Link href={`${homeText[selectedLanguage].LINK}`} className="inactive"> GUIDEBOOK </Nav.Link>
                    <Nav.Link href="https://www.youtube.com/user/DPRConstruction" className="inactive">YOUTUBE <Image src={linkBlack} className="linkLogo" /></Nav.Link>
                    <Button variant="secondary" onClick={logout} className="logoutButton">{navigationText[selectedLanguage].SIGN_OUT_BUTTON}</Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    }

}

export default Navigation;