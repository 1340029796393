import { UserAgentApplication } from 'msal';
import * as MicrosoftGraph from "@microsoft/microsoft-graph-client";
import {config} from "../constants/config";

const authentication = {
    userAgentApplication : new UserAgentApplication({
        auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri,
            authority: config.authority
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    }),
    getUserProfile : async () => {
        try {
                var accessToken = await authentication.getAccessToken(config.scopes);
                if (accessToken) {
                    const client = MicrosoftGraph.Client.init({
                        authProvider: (done) => {
                        done(null, accessToken);
                        }
                    });

                    const user = await client.api('/me').get();
                    const userStoreData = {displayName : user.displayName,givenName : user.givenName,surName : user.surname}
                    return (userStoreData);
                }
        }
        catch(err) {
           throw err;
        }
    },
    getAccessToken : async () => {
        try {
            var silentResult = await authentication.userAgentApplication.acquireTokenSilent({scopes: config.scopes});
    
            return silentResult.accessToken;
        }catch (err) {
            if (authentication.isInteractionRequired(err)) {
                var interactiveResult = await authentication.userAgentApplication.acquireTokenRedirect({scopes: config.scopes});
                return interactiveResult.accessToken;
            } else {
                throw err;
            }
        }
    },
    isInteractionRequired : (error) => {
        if (!error.message || error.message.length <= 0) {
            return false;
        }
        return (
            error.message.indexOf('consent_required') > -1 ||
            error.message.indexOf('interaction_required') > -1 ||
            error.message.indexOf('login_required') > -1
        );
    }
}

export default authentication;
