//dependencies
import {storePrefLang,actionTypes} from '../storeConstants';


export const alterLanguageReducer = (state = {...storePrefLang},actions) => {

    switch(actions.type){

        case actionTypes.UPDATE_LANGUAGE : 
                return {preferredLanguage : actions.payload};
                break;
        default : 
                return {...state};
                break;         
    }
}