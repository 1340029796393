//dependencies
import {actionTypes, errorObject} from '../storeConstants';


export const updateErrorReducer = (state = {...errorObject},actions) => {

    switch(actions.type){

        case actionTypes.UPDATE_ERROR_MSG : 
                return {errorMessage : actions.payload};
                break;
        default : 
                return {...state};
                break;         
    }
}